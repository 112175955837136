<template>
  <pp-form @submit.prevent="handleSubmit">
    <template v-slot="{ disabled }">
      <hr />

      <pp-card-body>
        <pp-simple-data>
          <template #left>Input</template>

          <template #right>
            <div class="text-sm flex items-center">
              <span class="text-water-600">Balance:</span>
              <pp-skeleton-loader
                v-if="tokenBalance.is('loading')"
                class="w-16 inline-block ml-1"
              ></pp-skeleton-loader>

              <span
                v-if="tokenBalance.is('resolved')"
                class="ml-1 font-medium"
                :title="tokenBalance.response.formattedAmount()"
                v-text="new BigNumberjs(tokenBalance.response.formattedAmount()).toFormat()"
                data-test="balance"
              ></span>
            </div>
          </template>
        </pp-simple-data>

        <PercentageSliderInput
          v-model="percentage"
          @change="handleChange"
          :disabled="
            !tokenBalance.is('resolved') ||
            (tokenBalance.response && tokenBalance.response.formattedAmount() == 0)
          "
        />

        <div class="mt-8">
          <div>Preferred Asset</div>

          <pp-tab-container
            class="flex-col md:flex-row space-x-0 space-y-1 md:space-x-1 md:space-y-0"
          >
            <pp-tab
              data-test="single-token-slider"
              v-for="token in market.tokenPair"
              :key="token.address"
              :active="tokenAddress === token.address"
              class="w-44 text-left"
              @click="tokenAddress = token.address"
            >
              <pp-token-preview :token="token"></pp-token-preview>
            </pp-tab>
          </pp-tab-container>
        </div>
      </pp-card-body>

      <template v-if="details.is('resolved')">
        <hr />

        <pp-card-body class="space-y-4 overflow-visible">
          <div>Output</div>

          <OutputTokenAmount
            data-test="output-amount"
            :address="details.response.outAmount.token.address"
            :amount="details.response.outAmount.formattedAmount()"
          />

          <pp-simple-data>
            <template #left>Rate</template>
            <template #right>
              <pp-rate
                :pair="[
                  {
                    unit: tokenFind(market.pair.find((addr) => addr !== tokenAddress)).symbol,
                    amount: 1,
                  },
                  {
                    unit: tokenFind(details.response.rate.token.address).symbol,
                    amount: details.response.rate.formattedAmount(),
                  },
                ]"
              ></pp-rate>
            </template>
          </pp-simple-data>

          <pp-simple-data>
            <template #left>
              Slippage tolerance

              <pp-tooltip-trigger direction="top">
                The transaction will revert if there is a large, unfavourable price movement before
                it is confirmed.
              </pp-tooltip-trigger>
            </template>
            <template #right>
              <div class="font-semibold">
                <pp-formatted-number
                  data-test="slippage-tolerance"
                  :value="details.response.slippage"
                  :max-decimal="8"
                ></pp-formatted-number>
                %
              </div>
            </template>
          </pp-simple-data>

          <pp-simple-data>
            <template #left>
              Price Impact

              <pp-tooltip-trigger direction="top">
                The difference between market price and estimated execution price due to trade size.
              </pp-tooltip-trigger>
            </template>
            <template #right>
              <div class="font-semibold">
                <pp-formatted-number
                  data-test="price-impact"
                  :value="100 * details.response.priceImpact"
                ></pp-formatted-number>
                %
              </div>
            </template>
          </pp-simple-data>

          <pp-simple-data>
            <template #left>
              Swap Fee
              <pp-tooltip-trigger direction="top">
                A portion of each swap goes to the liquidity providers and the treasury.
              </pp-tooltip-trigger>
            </template>
            <template #right>
              <div class="font-semibold">
                <pp-formatted-number
                  data-test="swap-fee"
                  :value="details.response.swapFee.formattedAmount()"
                  :humanize="false"
                  :max-decimal="8"
                ></pp-formatted-number>

                <pp-token-symbol
                  class="ml-2"
                  :address="details.response.swapFee.token.address"
                ></pp-token-symbol>
              </div>
            </template>
          </pp-simple-data>
        </pp-card-body>
      </template>

      <pp-card-actions>
        <pp-btn
          size="xl"
          class="flex w-full"
          type="submit"
          data-test="btn"
          :disabled="
            disabled ||
            percentage == 0 ||
            tokenBalance.is('loading') ||
            tokenBalance.response.formattedAmount() == 0
          "
          :loading="details.is('loading') || form.is('loading')"
        >
          Remove Liquidity
        </pp-btn>
      </pp-card-actions>
    </template>
  </pp-form>
</template>

<script>
  import Vue from 'vue'
  import Token from '@/domains/entities/Token'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import { generateEtherscanTxUrl, formatNumber } from '@/assets/helpers'
  import PercentageSliderInput from './PercentageSliderInput.vue'
  import OutputTokenAmount from '../OutputTokenAmount.vue'
  import BigNumberjs from 'bignumber.js'

  export default Vue.extend({
    components: { PercentageSliderInput, OutputTokenAmount },
    props: {
      market: { type: Object, required: true },
    },
    data() {
      return {
        tokenAddress: this.$props.market.pair[0],
        percentage: 0,
        timeout: null,
        interval: null,
        tokenBalance: createState(),
        details: createState(),
        form: createState(),
      }
    },
    computed: {
      params() {
        return {
          outToken: Token.query().find(this.tokenAddress),
          slippage: this.$app.state.slippage,
        }
      },
    },
    watch: {
      tokenAddress: {
        immediate: true,
        handler(val) {
          this.fetchTokenBalance(val)
        },
      },
      params() {
        this.handleChange()
      },
    },
    methods: {
      BigNumberjs,
      tokenFind(addr) {
        return Token.query().find(addr)
      },
      fetchTokenBalance() {
        new PromiseHandler(
          () =>
            this.market.token
              .contract(this.$store.getters['wallet/identity'])
              .balanceOf(this.$store.state.wallet.address),
          this.tokenBalance
        ).execute()
      },
      handleSubmit() {
        new PromiseHandler(
          () =>
            this.market
              .contract(this.$store.getters['wallet/identity'])
              .removeSingle({ ...this.params, percentage: this.percentage }),
          this.form
        )
          .execute()
          .then((response) => {
            const name = this.details.response.outAmount.token.name
            const amount = this.details.response.outAmount.formattedAmount()
            this.$notification.success({
              title: 'Remove liquidity',
              text: `Removed ${formatNumber(amount)} ${name} from ${this.market.token.name}.`,
              action: {
                url: generateEtherscanTxUrl(response.hash),
                urlText: 'View on explorer',
              },
            })

            this.$emit('success', response)
          })
          .catch(() => {
            this.$notification.error({
              title: 'Remove liquidity',
              text: `Unable to remove liquidity from ${this.market.token.name}`,
            })
          })
      },
      handleChange() {
        const slippage = this.params.slippage

        if (this.percentage > 0) {
          new PromiseHandler(
            () =>
              this.market
                .contract(this.$store.getters['wallet/identity'])
                .removeSingleDetails({ ...this.params, percentage: this.percentage }),
            this.details
          )
            .execute({ force: true })
            .then((response) => {
              this.details.response = { ...response, slippage }
              this.interval = setInterval(() => this.handleChange(), 60000)
            })
        }
      },
    },
  })
</script>
