var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pp-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var disabled = ref.disabled;
return [_c('hr'),_c('pp-card-body',[_c('pp-simple-data',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_vm._v("Input")]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"text-sm flex items-center"},[_c('span',{staticClass:"text-water-600"},[_vm._v("Balance:")]),(_vm.tokenBalance.is('loading'))?_c('pp-skeleton-loader',{staticClass:"w-16 inline-block ml-1"}):_vm._e(),(_vm.tokenBalance.is('resolved'))?_c('span',{staticClass:"ml-1 font-medium",attrs:{"title":_vm.tokenBalance.response.formattedAmount(),"data-test":"balance"},domProps:{"textContent":_vm._s(new _vm.BigNumberjs(_vm.tokenBalance.response.formattedAmount()).toFormat())}}):_vm._e()],1)]},proxy:true}],null,true)}),_c('PercentageSliderInput',{attrs:{"disabled":!_vm.tokenBalance.is('resolved') ||
          (_vm.tokenBalance.response && _vm.tokenBalance.response.formattedAmount() == 0)},on:{"change":_vm.handleChange},model:{value:(_vm.percentage),callback:function ($$v) {_vm.percentage=$$v},expression:"percentage"}}),_c('div',{staticClass:"mt-8"},[_c('div',[_vm._v("Preferred Asset")]),_c('pp-tab-container',{staticClass:"flex-col md:flex-row space-x-0 space-y-1 md:space-x-1 md:space-y-0"},_vm._l((_vm.market.tokenPair),function(token){return _c('pp-tab',{key:token.address,staticClass:"w-44 text-left",attrs:{"data-test":"single-token-slider","active":_vm.tokenAddress === token.address},on:{"click":function($event){_vm.tokenAddress = token.address}}},[_c('pp-token-preview',{attrs:{"token":token}})],1)}),1)],1)],1),(_vm.details.is('resolved'))?[_c('hr'),_c('pp-card-body',{staticClass:"space-y-4 overflow-visible"},[_c('div',[_vm._v("Output")]),_c('OutputTokenAmount',{attrs:{"data-test":"output-amount","address":_vm.details.response.outAmount.token.address,"amount":_vm.details.response.outAmount.formattedAmount()}}),_c('pp-simple-data',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_vm._v("Rate")]},proxy:true},{key:"right",fn:function(){return [_c('pp-rate',{attrs:{"pair":[
                {
                  unit: _vm.tokenFind(_vm.market.pair.find(function (addr) { return addr !== _vm.tokenAddress; })).symbol,
                  amount: 1,
                },
                {
                  unit: _vm.tokenFind(_vm.details.response.rate.token.address).symbol,
                  amount: _vm.details.response.rate.formattedAmount(),
                } ]}})]},proxy:true}],null,true)}),_c('pp-simple-data',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_vm._v(" Slippage tolerance "),_c('pp-tooltip-trigger',{attrs:{"direction":"top"}},[_vm._v(" The transaction will revert if there is a large, unfavourable price movement before it is confirmed. ")])]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"font-semibold"},[_c('pp-formatted-number',{attrs:{"data-test":"slippage-tolerance","value":_vm.details.response.slippage,"max-decimal":8}}),_vm._v(" % ")],1)]},proxy:true}],null,true)}),_c('pp-simple-data',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_vm._v(" Price Impact "),_c('pp-tooltip-trigger',{attrs:{"direction":"top"}},[_vm._v(" The difference between market price and estimated execution price due to trade size. ")])]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"font-semibold"},[_c('pp-formatted-number',{attrs:{"data-test":"price-impact","value":100 * _vm.details.response.priceImpact}}),_vm._v(" % ")],1)]},proxy:true}],null,true)}),_c('pp-simple-data',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_vm._v(" Swap Fee "),_c('pp-tooltip-trigger',{attrs:{"direction":"top"}},[_vm._v(" A portion of each swap goes to the liquidity providers and the treasury. ")])]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"font-semibold"},[_c('pp-formatted-number',{attrs:{"data-test":"swap-fee","value":_vm.details.response.swapFee.formattedAmount(),"humanize":false,"max-decimal":8}}),_c('pp-token-symbol',{staticClass:"ml-2",attrs:{"address":_vm.details.response.swapFee.token.address}})],1)]},proxy:true}],null,true)})],1)]:_vm._e(),_c('pp-card-actions',[_c('pp-btn',{staticClass:"flex w-full",attrs:{"size":"xl","type":"submit","data-test":"btn","disabled":disabled ||
          _vm.percentage == 0 ||
          _vm.tokenBalance.is('loading') ||
          _vm.tokenBalance.response.formattedAmount() == 0,"loading":_vm.details.is('loading') || _vm.form.is('loading')}},[_vm._v(" Remove Liquidity ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }