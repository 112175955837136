<template>
  <RemoveSingleAssetForm :market="market" :key="successCount" @success="successCount++" />
</template>

<script>
  import Vue from 'vue'
  import RemoveSingleAssetForm from '@/components/forms/RemoveSingleAssetForm.vue'

  export default Vue.extend({
    components: { RemoveSingleAssetForm },
    data() {
      return {
        successCount: 0,
      }
    },
    inject: ['market'],
  })
</script>
